import api from "../axios_service.js";

export async function fetchSenhasCliente(cliente_id) {
  const response = await api.get(`/gestor/senhas/${cliente_id}`);
  return response.data.result;
}

export async function postSenha(senha) {
  const response = await api.post("/gestor/senhas/add", senha);
  return response;
}

export function putSenha(id, senha) {
  return api.put(`/gestor/senhas/${id}`, senha);
}
