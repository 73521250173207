<template>
  <v-container>
    <v-card flat>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="addSenha">Adicionar</v-btn>
        <DialogSenhas
          v-if="dialogSenhas"
          :edit="edit"
          :dialogSenhas.sync="dialogSenhas"
          :cliente_id="cliente_id"
          :item="senha"
          @fetch-senhas="getSenhasCliente"
        />
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="senhas"
          item-key="id"
          :items-per-page="10"
          :sort-desc="true"
          class="data-tables data-tables__row-click"
          @click:row="editSenha"
        >
          <template v-slot:item.login="{ item }">
            <v-btn
              small
              @click.stop
              v-clipboard="() => item.login"
              v-clipboard:success="clipboardSuccessHandler"
              v-clipboard:error="clipboardErrorHandler"
              icon
            >
              <v-icon>
                mdi-content-copy
              </v-icon>
            </v-btn>
            {{ item.login }}
          </template>
          <template v-slot:item.senha="{ item }">
            <v-btn
              small
              @click.stop
              v-clipboard="() => item.senha"
              v-clipboard:success="clipboardSuccessHandler"
              v-clipboard:error="clipboardErrorHandler"
              icon
            >
              <v-icon>
                mdi-content-copy
              </v-icon>
            </v-btn>
            {{ item.senha }}
          </template>
          <template v-slot:item.descricao="{ item }">
            {{ item.descricao | excerpt(40) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { fetchSenhasCliente } from "@/api/gestor/senhas.js";

export default {
  name: "Senhas",

  props: {
    cliente_id: {
      type: String,
    },
  },

  data() {
    return {
      search: "",
      dialogSenhas: false,
      senhas: [],
      edit: false,
      senha: {},
    };
  },

  components: {
    DialogSenhas: () => import("./DialogSenhas.vue"),
  },

  computed: {
    headers() {
      return [
        {
          text: "Origem",

          value: "origem",
        },
        {
          text: "Login",

          value: "login",
        },
        {
          text: "Senha",

          value: "senha",
        },
        {
          text: "Descrição",
          width: "250px",
          value: "descricao",
        },
      ];
    },
  },

  methods: {
    clipboardSuccessHandler({ value }) {
      this.$toast.success("Copiado com sucesso para a clipboard!");
      console.log("success", value);
    },

    clipboardErrorHandler({ value }) {
      console.log("error", value);
      this.$toast.error("Ocorreu algum erro!");
    },

    addSenha() {
      this.edit = false;
      this.dialogSenhas = true;
    },
    editSenha(item) {
      this.edit = true;
      this.dialogSenhas = true;
      this.senha = { ...item };
    },
    getSenhasCliente() {
      this.loading = true;
      fetchSenhasCliente(this.cliente_id)
        .then((response) => {
          this.senhas = response;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getSenhasCliente();
  },
};
</script>

<style></style>
